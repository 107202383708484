import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useLoading } from "../components/common/LoadingContext"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthContext';
import { collection, addDoc } from 'firebase/firestore';
import { db, functions } from '../components/auth/Config';
import { Reservation } from '../components/reservations/Reservation';
import { parseFrenchDate } from '../components/common/Conversions';
import { extractProductList, Product } from '../components/common/ListBuilder';
import { MdCancel } from 'react-icons/md';


function generateOrderNumber(): number {
  const now = new Date();

  const pad = (num: number): string => num.toString().padStart(2, '0');


  const day = pad(now.getDate());
  const month = pad(now.getMonth() + 1);
  const year = now.getFullYear().toString().substr(-2);
  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());

  const orderNumber = `${day}${month}${year}${hours}${minutes}`;
  return Number(orderNumber);
}


const NewReservation: React.FC = () => {

  const navigate = useNavigate();
  const { user } = useAuth();
  const { loading, setLoading } = useLoading();
  const [customerMessage, setCustomerMessage] = useState<string>('');
  const [reservationNumber, setReservationNumber] = useState<string>('');
  const [country, setCountry] = useState<string>('CH');
  const [rentalCoefficient, setRentalCoefficient] = useState<number>(1);
  const [nip, setNip] = useState<number>(0);
  const [productTotal, setProductTotal] = useState<number>(0);
  const [firstName, setFirstName] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [productList, setProductList] = useState<Product[]>([]);
  const [newProductTitle, setNewProductTitle] = useState('');
  const [newProductQuantity, setNewProductQuantity] = useState(1);
  const [newProductPrice, setNewProductPrice] = useState(0);
  const [isWoo, setIsWoo] = useState<boolean>(false);





  const handleFetchOrderDetails = async () => {
    if (reservationNumber) {

      if (!user) {
        alert("Votre session a expiré");
        return;
      }
      
      setLoading(true, "Récupération");

      const getOrderDetails  = httpsCallable(functions, 'ce9e3a81d62ff0d864272663b6eccbad8c2851edb887995cf44d85c2');

      await getOrderDetails({orderNumber: reservationNumber })
      .then((async result => {

        setIsWoo(true);

        const orderData = (result as any).data;
        
        setProductList(extractProductList(orderData.line_items));

        setFirstName( orderData.billing.first_name);
        setLastName( orderData.billing.last_name);
        setEmail(orderData.billing.email);
        setPhoneNumber(orderData.billing.phone);
        setPickupDate(parseFrenchDate(orderData.meta_data.find((item: { key: string; }) => item.key === "pickup_").value));
        setReturnDate(parseFrenchDate(orderData.meta_data.find((item: { key: string; }) => item.key === "return_").value));
        setProductTotal(parseInt(orderData.total));
        setCreationDate(orderData.date_created);
        setCustomerMessage(orderData.customer_note);
        setNip(orderData.billing.postcode);
        setCountry(orderData.billing.country);
        setRentalCoefficient(orderData.meta_data.find((item: { key: string; }) => item.key === "rental_coefficient").value);


      })).catch((error) => {

        console.error('Error fetching order details:', error);
        alert(`${error}, Mais cela a peut être marché`);

      }).finally(() => {
        setLoading(false);
      });

    } else {

      alert('Veuillez entrer un numéro de réservation');
    }
  };

  const handleAddProduct = () => {
    const newProduct: Product = {
      title: newProductTitle,
      quantity: newProductQuantity,
      price: newProductPrice,
    };
    setProductList([...productList, newProduct]);
    setNewProductTitle('');
    setNewProductQuantity(1);
    setNewProductPrice(0);
  };
  
  const handleProductNameChange = (index: number, title: string) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, title } : item);
    setProductList(updatedList);
  };

  const handlePriceChange = (index: number, price: number) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, price } : item);
    setProductList(updatedList);
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, quantity } : item);
    setProductList(updatedList);
  };

  const handleRemoveProduct = (index: number) => {
    const newList = productList.filter((_, i) => i !== index);
    setProductList(newList);
  };


  //Code duplicate beurk
  const AddAsDaft = async () => {

    if (!user) {
      alert("Votre session a expiré");
      return;
    }

    setLoading(true, "Enregistrement");


    const newReservation: Omit<Reservation, 'id'> = {
      reservationNumber: 0,
      pickupDate: pickupDate,
      returnDate: returnDate,
      status: 'draft',
      firstName: firstName,
      lastName: lastName,
      email: email,
      productTotal: productTotal,
      nip: nip,
      country: country,
      phoneNumber: phoneNumber,
      calculatedSum: productTotal,
      productList: JSON.stringify(productList),
      customerMessage: customerMessage,
      rentalCoefficient: rentalCoefficient,
      userId: user.uid.substring(0, 5),
      creationDate: isDateInvalid(creationDate) ? new Date().toISOString() : creationDate,
      modifiedDate: new Date().toISOString(),
    };

    if (isWoo) {
      if (!reservationNumber || reservationNumber === '') {
        alert("Renseigner un numéro de réservation");
        return;
      }
      newReservation.reservationNumber = Number(reservationNumber);
    } else {
      newReservation.reservationNumber = generateOrderNumber();
    }

    try {

      await addDoc(collection(db, "reservations"), {
        ...newReservation
      }).then(() => {
        
        setLoading(false);
        navigate('/');

      });
        
    } catch (error) {

      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);

    }

  };

  const isDateInvalid = (dateString: string) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()); // Invalid if not a date or if the date is in the past
  };

  const addReservation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!user) {
      console.error("User est nul");
      alert('Vous n\'êtes plus connecté');
      return;
    }
  
    if (productList.length === 0) {
      alert('Veuillez ajouter au moins un produit à la réservation');
      return;
    }

    setLoading(true, "Enregistrement");

    const newReservation: Omit<Reservation, 'id'> = {
      reservationNumber: 0,
      pickupDate: pickupDate,
      returnDate: returnDate,
      status: 'new',
      firstName: firstName,
      lastName: lastName,
      email: email,
      productTotal: productTotal,
      nip: nip,
      country: country,
      phoneNumber: phoneNumber,
      productList: JSON.stringify(productList),
      customerMessage: customerMessage,
      rentalCoefficient: rentalCoefficient,
      userId: user.uid.substring(0, 5),
      creationDate:  isDateInvalid(creationDate) ? new Date().toISOString() : creationDate,
      modifiedDate: new Date().toISOString(),
    };

    if (isWoo) {
      if (!reservationNumber || reservationNumber === '') {
        alert("Renseigner un numéro de réservation");
        return;
      }
      newReservation.reservationNumber = Number(reservationNumber);
    } else {
      newReservation.reservationNumber = generateOrderNumber();
    }


    try {

      await addDoc(collection(db, "reservations"), {
        ...newReservation
      }).then(() => {
        
        setLoading(false);
        navigate('/');

      });
        
    } catch (error) {

      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);

    }

  };


  return (
    <div className="container mx-auto p-4">

      <button
        onClick={() => navigate('/')}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M15 19l-7-7 7-7"/>
        </svg>
        Retour
      </button>

      <br/>
      <h1 className="text-xl font-bold my-4 flex justify-center">CRÉATION (FICHE PRÉLIMINAIRE)</h1>
      

      {/*Réservation*/}
      <h2 className="text-xl font-bold my-4">Numéro de réservation</h2>

      <form onSubmit={addReservation} className="mb-4">


        <div className="mb-4">
          <label>
            <input
              type="checkbox"
              className='accent-purple-500'
              checked={isWoo}
              onChange={(e) => setIsWoo(e.target.checked)}
            />
            Est une réservation woo
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="reservationNumber" className="block text-gray-700 text-sm font-bold mb-2">
            Entrez le numéro woo
          </label>
          <input
            type="number"
            name="reservationNumber"
            min="0"
            value={reservationNumber}
            onChange={(e) => setReservationNumber(e.target.value)}
            required
            placeholder='N° Woo'
            disabled={!isWoo}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          type="button"
          onClick={handleFetchOrderDetails}
          disabled={loading || !isWoo}
          className={`${
            !isWoo ? 'bg-gray-500 hover:bg-gray-500 cursor-not-allowed' : 'bg-purple-500 hover:bg-purple-700'
          } text-white font-bold py-2 px-4 rounded mb-4`}
        >
          Magic-fill
        </button>
        <br/>
        <br/>
        {/*Renseignements*/}
        <h2 className="text-xl font-bold my-4">Renseignements</h2>
  

        <div className="flex-1">
          <label htmlFor="firstname" className="block text-gray-700 text-sm font-bold mb-2">
            Prénom
          </label>
          <input
            type="text"
            name="firstname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        
        <div className="flex-1">
          <label htmlFor="lastname" className="block text-gray-700 text-sm font-bold mb-2">
            Nom
          </label>
          <input
            type="text"
            name="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>

        <div className="flex-1">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        
        <div className="flex-1">
          <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
            Téléphone
          </label>
          <input
            type="text"
            name="phoneNumber"
            placeholder='+41 ...'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label htmlFor="nip" className="block text-gray-700 text-sm font-bold mb-2">
              NIP {'(Option)'}
            </label>
            <input
              type="number"
              name="nip"
              value={nip}
              onChange={(e) => setNip(parseInt(e.target.value))}
              placeholder='ex: 1030'
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        
          <div className="flex-1">
            <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
              Pays
            </label>
            <input
              type="text"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        
        <br/>
        
        <br/>
        {/*Liste du matériel*/}
        <h2 className="text-xl font-bold my-4">Liste du matériel</h2>

        
        <h3 className="text-lg my-4 font-bold justify-center flex">Tarif est pour les factures uniquement. Inscrire 0 pour OFFERT, laisser vide pour INCLUS</h3>

        {productList.map((product, index) => (
          <div key={index} className="flex flex-wrap -mx-3 mb-4 items-center">
          <div className="w-full sm:w-6/12 px-3">
              <input
                type="text"
                placeholder='Nom du produit'
                value={product.title}
                onChange={(e) => handleProductNameChange(index, e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-3/12 sm:w-2/12 px-3">
              <input
                type="number"
                min="0"
                placeholder='Quantité'
                value={product.quantity === 0 ? '' : product.quantity}
                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-3/12 sm:w-2/12 px-3">
              <input
                type="number"
                min="0"
                placeholder='Tarif (CHF)'
                value={product.price === 0 ? '' : product.price}
                onChange={(e) => handlePriceChange(index, parseInt(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-1/12 px-3 flex justify-center">
              <button
                onClick={() => handleRemoveProduct(index)}
                type="button"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                <MdCancel />
              </button>
            </div>
          </div>
        ))}

        <div className="flex flex-wrap -mx-3 mb-4 items-center">
          <div className="w-full sm:w-6/12 px-3">
            <input
              type="text"
              placeholder="Ajouter un produit"
              value={newProductTitle}
              onChange={(e) => setNewProductTitle(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-3/12 sm:w-2/12 px-3">
            <input
              type="number"
              min="0"
              placeholder="Quantité"
              value={newProductQuantity === 0 ? '' : newProductQuantity}
              onChange={(e) => setNewProductQuantity(parseInt(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-3/12 sm:w-2/12 px-3">
            <input
              type="number"
              min="0"
              step="0.01"
              placeholder="Tarif (CHF)"
              value={newProductPrice === 0 ? '' : newProductPrice}
              onChange={(e) => setNewProductPrice(parseFloat(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-1/12 px-3 flex justify-center">
            <button
              onClick={handleAddProduct}
              type="button"
              className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
            >
              +
            </button>
          </div>
        </div>

        <br/>
        <br/>


        {/*Détails*/}
        <h2 className="text-xl font-bold my-4">Détails</h2>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Prise en charge
        </legend>
        <input
          type="datetime-local"
          name="returnDateAndHour"
          value={pickupDate}
          onChange={(e) => setPickupDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <br/>
        <br/>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Retour
        </legend>
        <input
          type="datetime-local"
          name="returnDateAndHour"
          value={returnDate}
          onChange={(e) => setReturnDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <br/>
        <br/>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Coefficient de location
        </legend>

        <input type="number"
          name="rentalCoefficient"
          step={0.25}
          id="rentalCoefficient"
          min="1"
          value={rentalCoefficient}
          onChange={(e) => setRentalCoefficient(parseFloat(e.target.value))}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Définir un coefficient de location"
        />
        <br/>
        <br/>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Montant location (CHF)
        </legend>

        <input type="number"
          name="productTotal"
          id="productTotal"
          min="0"
          value={productTotal}
          onChange={(e) => setProductTotal(parseInt(e.target.value))}
          className="shadow appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Entrer un montant de réservation (CHF)"
        />

        <br/>

        
      

        <br/>
        <br/>
        {/*Note du client*/}
        <h2 className="text-xl font-bold my-4">Demande du client</h2>
        <div className="mb-4">
          <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
            Demande du client
          </label>
          <textarea name="note"
          rows={3}
          value={customerMessage}
          onChange={(e) => setCustomerMessage(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
          </textarea>
        </div>

        
        <br/>
        <br/>
        
    
        <div className="flex justify-end">
          <button type="button" onClick={AddAsDaft} disabled={loading} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Créer un Brouillon
          </button>
        </div>
        <br/>
        <div className="flex justify-end">
          <button type="submit" disabled={loading} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Créer
          </button>
        </div>
        <br/>
        <br/>
      </form>
    </div>
  );  
};

export default NewReservation;
