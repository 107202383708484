import { PDFDocument, PDFFont, PDFPage, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { Reservation } from '../reservations/Reservation';
import { Product } from './ListBuilder';
import { convertDateToFrenchFormat, parseFrenchDate } from './Conversions';
import { get } from 'http';

async function loadPoppinsFont() {
    const fontUrl = `${process.env.PUBLIC_URL}/pdfs/Poppins-Regular.ttf`;
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
    return fontBytes;
}

async function loadPoppinsBoldFont() {
    const fontUrl = `${process.env.PUBLIC_URL}/pdfs/Poppins-Bold.ttf`;
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
    return fontBytes;
}

function drawCenteredText(page: PDFPage, text: string, y: number, fontSize: number, font: PDFFont, color = rgb(0, 0, 0)) {
    const textWidth = font.widthOfTextAtSize(text, fontSize);
    const x = (page.getWidth() - textWidth) / 2;
    page.drawText(text, {
        x,
        y,
        size: fontSize,
        font,
        color,
    });
}

function drawTextWithCustomLineHeight(page: PDFPage, text: string, x: number, startY: number, fontSize: number, lineHeight: number, font: PDFFont, color = rgb(0, 0, 0)) : number {
    const lines = text.split('\n');
    let currentY = startY;
    let numberOfLines = 0;

    lines.forEach(line => {
        numberOfLines++;
        page.drawText(line, {
            x,
            y: currentY,
            size: fontSize,
            font,
            color,
        });
        currentY -= lineHeight; // Move down by the line height for the next line
    });

    return numberOfLines * (lineHeight + 2);
}

function drawProductList(page: PDFPage, products: Product[], startY: number, fontSize: number, lineHeight: number, font: PDFFont, color = rgb(0, 0, 0)): number {
    let currentY = startY;
    let startX = 90;
    let totalHeight = 0;

    products.forEach(product => {
        // Draw product title
        drawText(page, `-   ${product.title}`, startX, currentY, fontSize, font, color);

        // Draw quantity
        drawText(page, product.quantity.toString(), 420, currentY, fontSize, font, color);


        let formattedPrice = `${Math.round(product.price)}.-`;
        let totalPrice = `${Math.round(product.quantity * product.price)}.-`;
        if (product.price === 0) {
            formattedPrice = 'Offert';
            totalPrice = 'Offert';
        } else if (!product.price) {
            formattedPrice = 'Inclus';
            totalPrice = 'Inclus';
        }
        drawText(page, formattedPrice, 460, currentY, fontSize, font, color);
        drawText(page, totalPrice, 500, currentY, fontSize, font, color);


        // Move to the next line
        currentY -= lineHeight;
        totalHeight += lineHeight; //adjustement needed to avoid overlapping text
    });

    return totalHeight; // Return the total height occupied by the product list
}



function drawText(page: PDFPage, text: string, x: number, y: number, fontSize: number, font: PDFFont, color = rgb(0, 0, 0)) {
    page.drawText(text, {
        x,
        y,
        size: fontSize,
        font,
        color,
    });
}

function getSumOfAllFees(reservation: Reservation): number {
    let sum = 0;
    if (reservation.addDelivery) {
        sum += reservation.deliveryFee ? reservation.deliveryFee : 0;
    }
    if (reservation.addPickup) {
        sum += reservation.pickupFee ? reservation.pickupFee : 0;
    }
    if (reservation.installation) {
        sum += reservation.installationFee ? reservation.installationFee : 0;
    }
    if (reservation.adjustment) {
        sum += reservation.adjustmentFee ? reservation.adjustmentFee : 0;
    }
    if (reservation.dismantling) {
        sum += reservation.dismantlingFee ? reservation.dismantlingFee : 0;
    }
    if (reservation.onSite) {
        sum += reservation.onSiteFee ? reservation.onSiteFee : 0;
    }
    return sum;
}

async function generateInvoiceFrom(reservation: Reservation, isInvoice: boolean, to: string): Promise<Blob> {

    const airSoundColor = rgb(165 / 255, 68 / 255, 245 / 255);
    //Etape 0: définir les variables d'offset pour gérer la taille dynamique du document
    let offset = 0;
    let originalHeight = 774;
    

    // Étape 1: Charger le PDF depuis le dossier public
    const pdfUrl = isInvoice ? `${process.env.PUBLIC_URL}/pdfs/facture_airsound.pdf`: `${process.env.PUBLIC_URL}/pdfs/devis_airsound.pdf`;
    const arrayBuffer = await fetch(pdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    // Load the PNG image from a URL or local file
    const pngUrl = `${process.env.PUBLIC_URL}/res/total_border.png`; // Replace with your image URL
    const pngImageBytes = await fetch(pngUrl).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    // Register fontkit instance with the PDF document
    pdfDoc.registerFontkit(fontkit);
    
    // Etape 2: Charger poppins
    const poppinsFontBytes = await loadPoppinsFont();
    const poppins = await pdfDoc.embedFont(poppinsFontBytes);
    // Etape 2: Charger poppins
    const poppinsFontBoldBytes = await loadPoppinsBoldFont();
    const poppinsBold = await pdfDoc.embedFont(poppinsFontBoldBytes);

    // Get the first page of the document
    let page = pdfDoc.getPages()[0];

    // Set up font and colors

    const textColor = rgb(0, 0, 0); // Black color

    // Draw text on the PDF
    drawCenteredText(page, isInvoice ? "Facture" : "Devis", originalHeight, 20, poppinsBold);

    offset += 17;
    
    drawCenteredText(page, "AirSound Location", originalHeight - offset, 9, poppins);

    offset += 10;

    drawText(page, 'Réservation n° : ',
        372,
        originalHeight - offset,
        9,
        poppinsBold,
    );

    drawText(page, reservation.reservationNumber.toString(),
        450,
        originalHeight - offset,
        9,
        poppins,
    );

    offset += 14;

    drawText(page, 'Le :',
        430,
        originalHeight - offset,
        9,
        poppinsBold,
    );

    drawTextWithCustomLineHeight(page, (new Date().toLocaleDateString()),
        450,
        originalHeight - offset,
        9,
        12,
        poppins,
    );

    offset += 14;

    drawText(page, 'Au nom de :',
        390,
        originalHeight - offset,
        9,
        poppinsBold,
    );

    offset += drawTextWithCustomLineHeight(page, to,
        450,
        originalHeight - offset,
        9,
        12,
        poppins,
    );

    offset += 5;

    offset += drawTextWithCustomLineHeight(page, `       Voici le détail de notre proposition pour la location du matériel nécessaire, ainsi que la prestation\nde nos services. Notre offre est basée sur la garantie d'une prestation de qualité et d’un matériel fiable\net performant.`,
        70,
        originalHeight - offset,
        9,
        12,
        poppins,
    );

    
    offset += 5;

    drawText(page, 'Matériel loué :',
        70,
        originalHeight - offset,
        9,
        poppinsBold,
    );

    drawText(page, 'Qté',
        420,
        originalHeight - offset,
        9,
        poppins,
    );

    drawText(page, 'Unité',
        460,
        originalHeight - offset,
        9,
        poppins,
    );

    drawText(page, 'Total',
        500,
        originalHeight - offset,
        9,
        poppins,
    );

    offset += 20;

    offset += drawProductList(page, JSON.parse(reservation.productList), originalHeight - offset, 9, 12, poppins);
    //for every product in the list, draw the product title, quantity, price and total

    offset += 10;

    drawText(page, 'Coefficient de location : ',
        70,
        originalHeight - offset,
        9,
        poppins,
    );

    drawText(page, `x${reservation.rentalCoefficient}`,
        180,
        originalHeight - offset,
        9,
        poppins,
    );

    drawText(page, `Sous total :`,
        427,
        originalHeight - offset,
        9,
        poppins,
    );

    drawText(page, `${reservation.productTotal}.-`,
        500,
        originalHeight - offset,
        9,
        poppins,
    );

    offset += 27;

    drawText(page, 'Prestation de service :',
        70,
        originalHeight - offset,
        9,
        poppinsBold,
    );

    offset += 20;


    // Additional text fields and logic
    if (reservation.addDelivery || reservation.addPickup) {
        drawText(page, `-    Transport en véhicule : ${reservation.deliveryAddress}`,
            90,
            originalHeight - offset,
            9,
            poppins,
        );
        offset += 14;
        if (reservation.addDelivery) {
            drawText(page, `-    Livraison : ${new Date(reservation.pickupDate).toLocaleDateString()}`,
                110,
                originalHeight - offset,
                9,
                poppins,
            );
            drawText(page, `${reservation.deliveryFee}.-`,
                500,
                originalHeight - offset,
                9,
                poppins,
            );
            offset += 14;
        }
        if (reservation.addPickup) {
            drawText(page, `-    Reprise : ${new Date(reservation.returnDate).toLocaleDateString()}`,
                110,
                originalHeight - offset,
                9,
                poppins,
            );
            drawText(page, `${reservation.pickupFee}.-`,
                500,
                originalHeight - offset,
                9,
                poppins,
            );
            offset += 14;
        }
    }

    let line = '';
    let sum = 0;

    if (reservation.installation) {
        line = `-    Installation`;
        sum += reservation.installationFee ? reservation.installationFee : 0;
    }
    if (reservation.adjustment) {
        if (line) {
            line += ', réglage et tests techniques';
        } else {
            line = `-    Réglage et tests techniques`;
        }
        sum += reservation.adjustmentFee ? reservation.adjustmentFee : 0;
    }
    if (reservation.dismantling) {
        if (line) {
            line += `, démontage et rangement`;
        } else {
            line = `-    Démontage et rangement`;
        }
        sum += reservation.dismantlingFee ? reservation.dismantlingFee : 0;
    }

    if (line) {
        drawText(page, line,
            90,
            originalHeight - offset,
            9,
            poppins,
        );

        drawText(page, `${sum}.-`,
            500,
            originalHeight - offset,
            9,
            poppins,
        );

        offset += 14;
    }

    if (reservation.onSite) {
        drawText(page, `-    Présence sur site`,
            90,
            originalHeight - offset,
            9,
            poppins,
        );

        drawText(page, `${reservation.onSiteDuration} h`,
            460,
            originalHeight - offset,
            9,
            poppins,
        );

        drawText(page, `${reservation.onSiteFee}.-`,
            500,
            originalHeight - offset,
            9,
            poppins,
        );
        offset += 14;
    }

    offset += 6;

    drawText(page, `Sous total :`,
        427,
        originalHeight - offset,
        9,
        poppins,
    );

    const sumOfAllFees = getSumOfAllFees(reservation);

    drawText(page, `${sumOfAllFees}.-`,
        500,
        originalHeight - offset,
        9,
        poppins,
    );

    offset += 14;

    if (reservation.discount) {
        drawText(page, `Remise :`,
            427,
            originalHeight - offset,
            9,
            poppins,
        );

        drawText(page, `${reservation.discountAmount}.-`,
            500,
            originalHeight - offset,
            9,
            poppins,
        );
        offset += 14;
    }

    offset += 6;

    drawText(page, `Total :`,
        427,
        originalHeight - offset,
        9,
        poppins,
    );

    const { width, height } = pngImage.scale(1); // You can scale the image here
    const pngPadding = 6;

    // Define the position and size of the image on the PDF page
    const x = 500 - pngPadding;
    const y = originalHeight - offset - pngPadding; // Adjust '100' to your needs
    const imageWidth = poppins.widthOfTextAtSize(`${reservation.calculatedSum}.-`, 9) + 2 * pngPadding; // Set desired width
    const imageHeight = 19; // Maintain aspect ratio

    // Draw the image on the page
    page.drawImage(pngImage, {
        x,
        y,
        width: imageWidth,
        height: imageHeight,
    });

    drawText(page, `${reservation.calculatedSum}.-`,
        500,
        originalHeight - offset,
        9,
        poppins,
    );
    offset += 14;


    drawText(page, `Nils Rothacher`,
        70,
        90,
        9,
        poppinsBold,
    );
    drawTextWithCustomLineHeight(page, `Chemin du Vallon 17, 1030 Bussigny\ncontact@airsoundlocation.ch\nairsoundlocation.ch`,
        70,
        77,
        9,
        13,
        poppins,
    );

    if (isInvoice) {
        page = pdfDoc.getPages()[1];

        drawText(page, `${reservation.calculatedSum}.-`,
            235,
            65,
            20,
            poppins);
    
        drawText(page, `${reservation.calculatedSum}.-`,
            102,
            85,
            14,
            poppins);
    }


    // Serialize the PDF to bytes (Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Create a Blob from the PDFBytes and generate a URL
    return new Blob([pdfBytes], { type: 'application/pdf' });
}

export default generateInvoiceFrom;
